$ui-specifier: ui-components;

.#{$ui-specifier} {
  &,
  &::before,
  &::after {
    border-style: solid;
    border-width: 0;
    box-sizing: border-box;
    font-family: ACTEasy, sans-serif;
  }

  &::before,
  &::after {
    --tw-content: '';
  }
  @at-root {
    html#{&} {
      font-family: ACTEasy, sans-serif;
      line-height: 1.5;
      tab-size: 4;
      tab-size: 4;
      text-size-adjust: 100%;
    }
    body#{&} {
      line-height: inherit;
      margin: 0;
    }
    hr#{&} {
      border-top-width: 1px;
      color: inherit;
      height: 0;
    }
    abbr:where([title])#{&} {
      text-decoration: underline dotted;
    }
    h1#{&},
    h2#{&},
    h3#{&},
    h4#{&},
    h5#{&},
    h6#{&} {
      font-size: inherit;
      font-weight: inherit;
    }
    a#{&} {
      color: inherit;
      text-decoration: inherit;
    }
    b#{&},
    strong#{&} {
      font-weight: bolder;
    }
    code#{&},
    kbd#{&},
    samp#{&},
    pre#{&} {
      font-family: 'Courier New';
      font-size: 1em;
    }
    small#{&} {
      font-size: 80%;
    }
    sub#{&},
    sup#{&} {
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }
    sub#{&} {
      bottom: -0.25em;
    }
    sup#{&} {
      top: -0.5em;
    }
    table#{&} {
      border-collapse: collapse;
      border-color: inherit;
      text-indent: 0;
    }
    button#{&},
    input#{&},
    optgroup#{&},
    select#{&},
    textarea#{&} {
      color: inherit;
      font-size: 100%;
      font-weight: inherit;
      line-height: inherit;
      margin: 0;
    }
    button#{&},
    select#{&} {
      text-transform: none;
    }
    button#{&},
    [type='button']#{&} [type='reset']#{&},
    [type='submit']#{&} {
      appearance: button;
      background-color: transparent;
      background-image: none;
      padding: 0;
    }
    :-moz-focusring#{&} {
      outline: auto;
    }
    :-moz-ui-invalid#{&} {
      box-shadow: none;
    }
    progress#{&} {
      vertical-align: baseline;
    }
    ::-webkit-inner-spin-button#{&},
    ::-webkit-outer-spin-button#{&} {
      height: auto;
    }
    [type='search']#{&} {
      appearance: textfield;
      outline-offset: -2px;
    }
    ::-webkit-search-decoration#{&} {
      appearance: none;
    }
    ::-webkit-file-upload-button#{&} {
      appearance: button;
      font: inherit;
    }
    summary#{&} {
      display: list-item;
    }
    blockquote#{&},
    dl#{&},
    dd#{&},
    h1#{&},
    h2#{&},
    h3#{&},
    h4#{&},
    h5#{&},
    h6#{&},
    hr#{&},
    figure#{&},
    p#{&},
    pre#{&} {
      margin: 0;
    }
    fieldset#{&} {
      margin: 0;
      padding: 0;
    }
    legend#{&} {
      padding: 0;
    }
    ol#{&},
    ul#{&},
    menu#{&} {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    ul#{&} {
      &::-webkit-scrollbar {
        background: transparent;
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 1ex;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: #000;
          border-radius: 4px;
        }

        &::-webkit-scrollbar {
          background: #a39f99;
          border-radius: 4px;
        }
      }
    }
    textarea#{&} {
      resize: vertical;
    }
    input::placeholder#{&},
    textarea::placeholder#{&} {
      opacity: 1;
    }
    button#{&},
    #{&} [role='button'] {
      cursor: pointer;
    }
    :disabled#{&} {
      cursor: default;
    }
    img#{&},
    svg#{&},
    video#{&},
    canvas#{&},
    audio#{&},
    iframe#{&},
    embed#{&},
    object#{&} {
      display: block;
      vertical-align: middle;
    }
    img#{&},
    video#{&} {
      height: auto;
      max-width: 100%;
    }
    [hidden]#{&} {
      display: none;
    }
  }
}

.#{$ui-specifier} {
  *,
  ::before,
  ::after {
    border-style: solid;
    border-width: 0;
    box-sizing: border-box;
    font-family: ACTEasy, sans-serif;
  }

  ::before,
  ::after {
    --tw-content: '';
  }

  html {
    font-family: ACTEasy, sans-serif;
    line-height: 1.5;
    tab-size: 4;
    tab-size: 4;
    text-size-adjust: 100%;
  }

  body {
    line-height: inherit;
    margin: 0;
  }

  hr {
    border-top-width: 1px;
    color: inherit;
    height: 0;
  }

  abbr:where([title]) {
    text-decoration: underline dotted;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp,
  pre {
    font-family: 'Courier New';
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  table {
    border-collapse: collapse;
    border-color: inherit;
    text-indent: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type='button'] [type='reset'],
  [type='submit'] {
    appearance: button;
    background-color: transparent;
    background-image: none;
    padding: 0;
  }

  :-moz-focusring {
    outline: auto;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    appearance: none;
  }

  ::-webkit-file-upload-button {
    appearance: button;
    font: inherit;
  }

  summary {
    display: list-item;
  }

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  legend {
    padding: 0;
  }

  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    &::-webkit-scrollbar {
      background: transparent;
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 1ex;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #000;
        border-radius: 4px;
      }

      &::-webkit-scrollbar {
        background: #a39f99;
        border-radius: 4px;
      }
    }
  }

  textarea {
    resize: vertical;
  }

  input::placeholder,
  textarea::placeholder {
    opacity: 1;
  }

  button,
  [role='button'] {
    cursor: pointer;
  }

  :disabled {
    cursor: default;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  img,
  video {
    height: auto;
    max-width: 100%;
  }

  [hidden] {
    display: none;
  }
}
