@import '@circlek-global/lds-components/tailwind.config.scss';

@tailwind utilities;
@tailwind base;
@tailwind components;

// regular style toast
@import 'ngx-toastr/toastr';

@font-face {
  font-family: 'ACTEasy';
  src: url('/assets/fonts/ACTEasy.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ACTEasy';
  src: url('/assets/fonts/ACTEasyVar-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'ACTEasy';
  src: url('/assets/fonts/ACTEasy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ACTEasy';
  src: url('/assets/fonts/ACTEasy-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'ACTEasy';
  src: url('/assets/fonts/ACTEasy-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ACTEasy';
  src: url('/assets/fonts/ACTEasy-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'ACTEasy';
  src: url('/assets/fonts/ACTEasy-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'ACTEasy';
  src: url('/assets/fonts/ACTEasy-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'ACTEasy';
  src: url('/assets/fonts/ACTEasy-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'ACTEasy';
  src: url('/assets/fonts/ACTEasy-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'ACTEasy', 'Helvetica Neue', sans-serif !important;
}

a[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

button, span, a, p, label {
  font-family: 'ACTEasy', "Helvetica Neue", sans-serif !important;
}

label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
